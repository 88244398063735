:root {
    --bold: 600;
    --extrabold: 700;
    --medium: 500;
    --regular: 400;
    --light: 300;
    --thin: 100;
    --blue: #2f80ed;
    --dark-blue: #2666be;
    --light-blue: #ebf3fe;
    --light-green: #e3ffed;
    --green: #12b76a;
    --light-red: #fde6e4;
    --red: #ef574c;
    --black: #2b2f34;
    --gray: #e0e0e0;
    --light-gray: #f5f5f5;
    --dark-gray: #c4c4c4;
    --white: #ffffff;
    --para: 14px;
    --big-para: 16px;
    --heading: 38px;
    --medium-heading: 28px;
    --small-heading: 22px;
    --highlighter-font: 18px;
    --mobile: 480px;
    --tablet: 768px;
    --laptop-sm: 1024px;
    --laptop-md: 1280px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /*font-size: var(--para);*/
    font-family: 'Inter', sans-serif;
    /*color: var(--black);*/
}

button {
    cursor: pointer;
}

ul {
    list-style: none;
}

.loader-animation-div {
    padding: 10px;
}

.loader-animation span {
    background-color: var(--green) !important;
}
