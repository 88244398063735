@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

.hints-div{
    padding: 5px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    min-height: 100px;
}


.hints-buttons{
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #E0E0E0;
    cursor: pointer;
}

.hints-data{
    padding: 10px;
}
.hints-data>p{
    margin: 0px;
    color: #24292e;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    font-size: 14px;
}

.selected{
    color:#2666BE;
    font-family: 'Inter', sans-serif;
    letter-spacing: -0.008em;
    font-weight: 600;
    font-size: 15px;
    border-bottom: 2px solid #2666BE;
    text-align: center;
    width: 25%;
}

.notselected{
    color: #AEB3BB;
    font-family: 'Inter', sans-serif;
    letter-spacing: -0.008em;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    min-width: 25%;
}

.questions-all-stats{
    display: flex;
    /* margin-top: 15px; */
    align-items: center;
}
.question-stat{
    display: flex;
    flex-wrap: wrap;
    width: auto;
    align-items: center !important;
    margin-right: 10px;
}

.question-stat>p{
    align-items: center !important;
}