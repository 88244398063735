.contest-navigation {
    width: 100%;
    padding: 0px 5%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 70px;
    background-color: var(--black);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    .banner-name {
        width: 25%;

        h3 {
            color: var(--white);
        }
    }

    .navigation-bar {
        width: 75%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        .end-contest-btn {
            background-color: var(--red);
            color: var(--white);
            padding: 5px 10px;
            border-radius: 8px;
            border: none;
            margin-left: 20px;
        }

        .webcam {
            height: 60px;
            border-radius: 5px;
            border: 3px solid #12b76a;
        }

        .progress {
            width: 150px;
            margin: 0 20px;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;

            p {
                color: var(--white);
            }

            .progress-bar {
                background-color: var(--white);
                width: 150px;
                height: 7px;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                border: 1px solid var(--white);

                .filled {
                    background-color: var(--red);
                    //width: 50px;
                    height: 6px;
                    border-radius: 5px;
                }
            }
        }

        .window-timer {
            width: min-content;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;

            p {
                color: var(--white);
                font-size: var(--para);
                text-transform: uppercase;
                margin-right: 10px;
            }

            .timer {
                #countdown {
                    ul {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        li {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            color: var(--white);
                            width: 50px;
                            height: 50px;
                            border: 1px solid var(--white);
                            border-radius: 5px;
                            margin: 0px 10px;

                            span {
                                color: var(--white);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:500px) {
    .contest-navigation {
        width: 100%;
        padding: 0px 5%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        height: 70px;
        background-color: var(--black);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    
        .banner-name {
            width: 25%;
        display: none;
    
            h3 {
                color: var(--white);
            }
        }
    
        .navigation-bar {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
    
            .end-contest-btn {
                background-color: var(--red);
                color: var(--white);
                padding: 5px 10px;
                border-radius: 8px;
                border: none;
                margin-left: 20px;
                width: 60%;
                font-size: 12px;
            }
    
            .webcam {
                display: none;
            }
    
            .progress {
                width: 150px;
                margin: 0 20px;
                display: flex;
               
                flex-direction: column;
                align-items: start;
                justify-content: start;
    
                p {
                    color: var(--white);
                }
    
                .progress-bar {
                    background-color: var(--white);
                    width: 150px;
                    height: 7px;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: start;
                    border: 1px solid var(--white);
    
                    .filled {
                        background-color: var(--red);
                        //width: 50px;
                        height: 6px;
                        border-radius: 5px;
                    }
                }
            }
    
            .window-timer {
                width: min-content;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: start;
    
                p {
                    color: var(--white);
                    font-size: 10px;
                    text-transform: uppercase;
                    margin-right: 10px;
                    display: none;
                }
    
                .timer {
                    #countdown {
                        ul {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
    
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                color: var(--white);
                                width: 40px;
                                height: 40px;
                                border: 1px solid var(--white);
                                border-radius: 5px;
                                margin: 0px 10px;
                                font-size: 10px;
    
                                span {
                                    color: var(--white);
                                }
                            }
                        }
                    }
                }
            }
        }
    }  
   
}
