.dashboard {
    width: 80%;
    margin-left: 10%;
    height: calc(100vh - 70px);
    display: flex;
    align-items: start;
    //background-color: #fafafa;

    .welcome {
        font-size: var(--heading);
        font-weight: var(--medium);
        letter-spacing: 1px;
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .column-1 {
        width: 60%;
        height: 100%;

        .all-contest-container {
            width: 70%;
            border-top: 1px solid #eeeeee;
            height: calc(100vh - 220px);
            overflow-x: scroll;

            .title {
                padding: 20px 0px;
                letter-spacing: 1px;
                text-transform: uppercase;
            }

            .all-contest-list {
                padding-right: 5px;

                .contest-item {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    min-height: 100px;
                    height: max-content;
                    margin-bottom: 20px;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    //border-radius: 5px;

                    .contest-item-name {
                        min-height: 100px;
                        height: max-content;
                        background-color: var(--green);
                        width: 40%;
                        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
                        padding: 10px 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        justify-content: center;

                        h3 {
                            color: var(--white);
                            letter-spacing: 1.5px;
                            text-transform: capitalize;
                        }
                    }

                    .contest-item-details {
                        width: 60%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 20px;

                        h4 {
                            display: flex;
                            font-size: 18px;
                            align-items: center;

                            svg {
                                margin-right: 10px;
                            }
                        }

                        button {
                            padding: 10px 20px;
                            background-color: var(--blue);
                            border: none;
                            color: var(--white);
                            border-radius: 4px;
                            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                        }
                    }
                }
            }
        }

        .contest-card {
            width: 90%;
            border-top: 1px solid #eeeeee;
.column-2-only-mobile{
    display: none;
}
            h3 {
                padding-bottom: 10px;
                font-size: var(--medium-heading);
                //border-bottom: 1px solid #eeeeee;
                font-weight: var(--medium);
            }

            .view-all-contest {
                margin-top: 50px;
                padding: 10px 20px;
                background-color: var(--blue);
                border: none;
                color: var(--white);
                border-radius: 4px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }

            .contest-details {
                border-radius: 4px;
                overflow: hidden;
                margin-top: 20px;
                background: #ffffff;
                /* Gray / gray-5 */

                //border: 1px solid #d9d9d9;
                /* Shadow/md */

                box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
                    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                //border-radius: 8px;

                .head {
                    h4 {
                        font-size: var(--small-heading);
                        font-weight: 400;
                        letter-spacing: 2px;
                        background-color: var(--green);
                        //clip-path: polygon(0 0, 0 100%, 100% 70%, 100% 0);
                        min-height: 100px;
                        height: max-content;
                        //border-radius: 8px 8px 0px 0px;
                        margin-bottom: 10px;
                        padding: 20px;
                        color: var(--white);
                    }
                }

                .timing {
                    //margin: 10px 0px;
                    padding: 5px 20px;

                    ul {
                        li {
                            font-size: 16px;
                            letter-spacing: 1px;
                            margin: 10px 0px;
                            color: var(--black);

                            span {
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                .already-attempted {
                    padding: 20px;
                    width: 100%;
                    font-size: 20px;
                    color: var(--green);
                    text-align: center;
                    text-transform: capitalize;
                    letter-spacing: 1px;
                }

                .blacklisted-text{
                    padding:30px;
                    color:red;
                }

                .start-buttons {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: end;

                    .start-btn {
                        background-color: var(--light-green);
                        border-radius: 5px;
                        padding: 8px 15px;
                        border: 1px solid var(--green);
                        color: var(--green);
                        margin: 10px 20px;
                    }
                }

                .deadline-passed-text {
                    color: red;
                    text-align: center;
                    font-size: 20px;
                    margin: 0px 20px 15px 5px;
                    text-align: center;
                }
            }
        }
    }

    .column-2 {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .live-box {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            width: 100%;

            h4 {
                text-transform: uppercase;
                font-size: var(--small-heading);
                font-weight: var(--medium);
                margin-bottom: 10px;
            }
        }

        .timer {
            #countdown {
                ul {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    li {
                        width: 80px;
                        height: 80px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--light-green);
                        color: var(--green);
                        font-size: var(--small-heading);
                        margin: 0px 5px;
                        border: 1px solid var(--green);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                        border-radius: 5px;

                        span {
                            margin-bottom: 5px;
                            color: var(--green);
                            font-size: var(--small-heading);
                        }
                    }
                }
            }
        }

        
    }

    .column-3{
        width: 40%;
        height: 90vh;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        position: relative;
        // .lottie-animation {
        //     position: absolute;
        //     bottom: 0px;
        //     right: 0px;
        //     width: 90%;
        //     margin: auto;
        // }
    }
}


@media screen and (max-width:500px){
    .dashboard {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        margin-left: 0;
        padding: 10px 20px;
        height: calc(100vh - 70px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        //background-color: #fafafa;
    
        .welcome {
            font-size: 28px;
            font-weight: var(--medium);
            letter-spacing: 1px;
            margin-top: 15px;
            margin-bottom: 10px;
        }
    
        .column-1 {
            width: 100%;
            // height: 100%;
            // overflow: hidden;
    
            .all-contest-container {
                width: 100%;
                
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-top: 1px solid #eeeeee;
                // height: calc(100vh - 220px);
                height: max-content;
                overflow-x:hidden ;
    
                .title {
                    width: 100%;
                    text-align: left;
                    padding: 15px 0px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    font-size: 16px;
                    color: #1872e7;
                    
                }
    
                .all-contest-list {
                    
                    padding-right: 5px;
                    width: 100%;
                    .contest-item {
                        border-radius: 4px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: start;
                        min-height: 100px;
                        height: max-content;
                        margin-bottom: 20px;
                        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                        //border-radius: 5px;
    
                        .contest-item-name {
                            min-height: 100px;
                            height: max-content;
                            background-color: var(--green);
                            width: 100%;
                            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                            padding: 10px;
                            display: flex;
                            flex-direction: column;
                            align-items: start;
                            justify-content: center;
    
                            h3 {
                                color: var(--white);
                                letter-spacing: 1.5px;
                                text-transform: capitalize;
                                font-size: 16px;
                            }
                        }
    
                        .contest-item-details {
                            width:100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 20px;
    
                            h4 {
                                display: flex;
                                font-size: 18px;
                                align-items: center;
    
                                svg {
                                    margin-right: 10px;
                                }
                            }
    
                            button {
                                padding: 10px 20px;
                                background-color: var(--blue);
                                border: none;
                                color: var(--white);
                                border-radius: 4px;
                                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                            }
                        }
                    }
                }
            }
    
            .contest-card {
                width: 100%;
                border-top: 1px solid #eeeeee;
    
                .column-2-only-mobile{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: relative;
            
                    .live-box {
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: start;
                        width: 100%;
            
                        h4 {
                            text-transform: uppercase;
                            font-size: 18px;
                            color: #054292;
                            font-weight: var(--medium);
                            margin-bottom: 10px;
                        }
                    }
            
                    .timer {
                        #countdown {
                            ul {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
            
                                li {
                                   
                                    width: 60px;
                                    height: 60px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: var(--light-green);
                                    color: var(--green);
                                    font-size: 18px;
                                    margin: 0px 5px;
                                    border: 1px solid var(--green);
                                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                                    border-radius: 5px;
            
                                    span {
                                        margin-bottom: 5px;
                                        color: var(--green);
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                    }
                }


                h3 {
                    padding-bottom: 10px;
                    font-size: var(--medium-heading);
                    //border-bottom: 1px solid #eeeeee;
                    font-weight: var(--medium);
                }
    
                .view-all-contest {
                    margin-top: 50px;
                    padding: 10px 20px;
                    background-color: var(--blue);
                    border: none;
                    color: var(--white);
                    border-radius: 4px;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                }
    
                .contest-details {
                    margin-top: 20px;
                    background: #ffffff;
                    border-radius: 4px;
                overflow: hidden;
                    /* Gray / gray-5 */
    
                    //border: 1px solid #d9d9d9;
                    /* Shadow/md */
    
                    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
                        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                    //border-radius: 8px;
    
                    .head {
                        h4 {
                            font-size: var(--small-heading);
                            font-weight: 400;
                            letter-spacing: 2px;
                            background-color: var(--green);
                            //clip-path: polygon(0 0, 0 100%, 100% 70%, 100% 0);
                            min-height: max-content;
                            height: max-content;
                            //border-radius: 8px 8px 0px 0px;
                            margin-bottom: 10px;
                            padding: 20px;
                            color: var(--white);
                        }
                    }
    
                    .timing {
                        //margin: 10px 0px;
                        padding: 5px 20px;
    
                        ul {
                            li {
                                font-size: 16px;
                                letter-spacing: 1px;
                                margin: 10px 0px;
                                color: var(--black);
    
                                span {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
    
                    .already-attempted {
                        padding: 20px;
                        width: 100%;
                        font-size: 20px;
                        color: var(--green);
                        text-align: center;
                        text-transform: capitalize;
                        letter-spacing: 1px;
                    }
    
                    .start-buttons {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: end;
    
                        .start-btn {
                            background-color: var(--light-green);
                            border-radius: 5px;
                            padding: 8px 15px;
                            border: 1px solid var(--green);
                            color: var(--green);
                            margin: 10px 20px;
                        }
                    }
                }
            }
        }
    
        .column-2 {
           display: none;
            
        }
    
        .column-3{
            width: 40%;
            height: 100%;
            margin-top: 100px;
            .lottie-animation{
                transform: scale(2);
            }
            
        }
    }  
}