.error-fallback {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(253, 230, 228, 0.7);

    .error-card {
        //border: 1px solid rgba(239, 87, 76);
        padding: 30px;
        border-radius: 10px;
        width: 70%;
        max-height: 70vh;
        overflow: scroll;
        background-color: rgb(253, 230, 228);
        //box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        //    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

        svg {
            width: 300px !important;
        }

        h1 {
            color: rgba(239, 87, 76);
        }

        p {
            margin-top: 20px;
            color: rgba(239, 87, 76);
        }
    }

    button {
        margin-top: 20px;
        background: rgba(239, 87, 76);
        border-radius: 6px;
        padding: 8px 20px 8px 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
    }
}
