.redirect-modal-container {
    background: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 420px;
    margin: auto;

    .animation {
        width: 280px;
        margin: auto;
    }
}

@media screen and (max-width: 500px) {
    .redirect-modal-container {
        width: 90%;

        .animation {
            width: 230px;
        }

        .heading-primary {
            font-size: 16px;
        }

        .heading-secondary {
            font-size: 20px;
        }
    }
}