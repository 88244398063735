.idle {
    height: calc(100vh - 100px);
    width: 100%;
}

.idle .left-pane {
    height: 100%;
}

.idle .confetti {
    position: fixed;
    top: 0;
    width: 50%;
    left: 25%;
    z-index: 100;
}

.idle .overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 400;
}

.idle .celebration,
.idle .failed-pop-up {
    position: relative;
    width: 30%;
    background-color: white;
    padding: 20px 50px;
    z-index: 500;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.idle .pop-up,
.idle .failed-pop-container {
    /*height: 200px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.idle .celebration .pop-up-close,
.idle .failed-pop-up .pop-up-close {
    font-size: 20px;
    position: absolute;
    right: 20px;
    cursor: pointer;
    z-index: 5000;
}

.idle .pop-up h3 {
    color: #12b76a;
}

.idle .failed-pop-up h3 {
    color: #ef574c;
}

.idle .pop-up .congrats,
.idle .failed-pop-up .failed-svg {
    height: 100px;
}

.idle .left-pane {
    height: 100vh;
    overflow: hidden;
}

.question-container {
    height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
}

.idle .left-pane .reflex-top-bar {
    height: 50px;
    width: 100%;
    margin-top: 20px;
    padding: 5px 0;
    border-bottom: 1px solid #eeeeee;
}

.idle .left-pane .reflex-top-bar .tab-list {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    color: #2f80ed;
    margin-left: 20px;
}

.idle .left-pane .reflex-top-bar .tab-list li {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: 5px;
}

.idle .left-pane .reflex-top-bar .tab-list li.active {
    background-color: #e1edf7;
}

.idle .left-pane .question-meta-info {
    height: 40px !important;
}

.idle .left-pane .question-meta-info .question-meta-info-list {
    height: 50px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 20px;

    .solved-success {
        background-color: var(--green);
        padding: 5px 10px;
        font-size: 18px;
        color: var(--white);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
}

.idle .left-pane .question-meta-info .question-meta-info-list .difficulty-item {
    height: 50px;
}

.idle .left-pane .question-meta-info .question-meta-info-list li {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.idle
    .left-pane
    .question-meta-info
    .question-meta-info-list
    .difficulty-item
    .question-difficulty {
    height: 30px;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.idle .left-pane .question-meta-info .question-meta-info-list .max-score {
    height: 30px;
    padding: 5px 10px;
    font-size: 14px;
}

.idle .left-pane .question-meta-info .question-meta-info-list .max-score svg {
    margin-right: 5px;
    font-size: 18px;
}

.idle .submission {
    padding: 20px;
    width: 100%;
    height: 100%;
}

.idle .submission .loading-submission {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.idle .question-panel .reflex-question-content {
    height: calc(100vh - 150px);
    overflow: scroll;
}

.idle .question-panel .reflex-question-content::-webkit-scrollbar {
    display: none;
}

.idle .submission .testcase-container {
    height: calc(100vh - 300px);
    overflow: scroll;
    font-size: 14px;
    margin-top: 10px;
}

.idle .submission .testcase-container::-webkit-scrollbar {
    display: none;
}

.idle .submission .submission-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    padding: 20px;
}

.idle .submission .no-submission {
    height: calc(100vh - 500px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #aaaaaa;
    text-align: center;
    width: 90%;
    margin: auto;
}

.idle .submission .submission-bar .testcase-total-score {
}

.idle .submission .submission-bar .testcase-total-results {
}

.idle .submission .testcase {
    border: 1px solid #eeeeee;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
}

.idle .submission .testcase .testcase-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.idle .submission .testcase .testcase-head .testcase-number {
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 700 !important;
}

.idle .submission .testcase .testcase-head .passed {
    color: #12b76a;
    background-color: #e3ffed;
    padding: 10px 20px;
    border-radius: 5px;
    letter-spacing: 1px;
}

.idle .submission .testcase .testcase-head .failed {
    color: #ef574c;
    background-color: #fde6e4;
    padding: 10px 20px;
    border-radius: 5px;
    letter-spacing: 1px;
}

.idle .submission .testcase .testcase-cpu-usage {
    display: flex;
    align-items: center;
    justify-content: start;
}

.idle .submission .testcase .testcase-cpu-usage p {
    margin-right: 20px;
}

.idle .submission .testcase .view-testcase-btn {
    background-color: #ebf3fe;
    color: #2f80ed;
    padding: 8px 15px;
    margin: 10px 0;
    border-radius: 5px;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.idle .submission .testcase .note {
}

.idle .submission .testcase .testcase-input {
    width: 90%;
    margin-top: 10px;
    resize: none;
    font-family: monospace;
    height: 100px;
    overflow: scroll;
    border-radius: 5px;
    outline: none;
    padding: 10px;
    /*background-color: #ffffff;*/
    color: #1d1e1f;
}

.idle .right-pane {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    background-color: #ffffff;
}

.idle .right-pane .reflex-container > .reflex-element {
    overflow: hidden;
}

.idle .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
}

.idle .editor-box {
    width: 100%;
}

.idle #editorPanel {
    width: 100%;
    height: 100%;
    background-color: var(--black);
}

.idle .editor-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/*.idle .view-lines {*/
/*	font-size: 14px !important;*/
/*}*/

.code-editor-container {
    width: 100%;
}

.main-editor {
    height: calc(100vh - 170px);
    background-color: var(--black);
}

.editor-box-container {
    height: calc(100vh - 170px);
    display: flex;
    flex-direction: column;
}

.editor-loader {
    margin: auto;
}

.editor-box-panel {
    background-color: var(--black);
    border-bottom: 1px solid var(--white);
    border-top: 1px solid var(--white);
    //display: flex;
    //flex-direction: column;
}

/*.custom-testcase-dialog {*/
/*	!*height: 30%;*!*/
/*	display: flex;*/
/*	background-color: black;*/
/*	color: black;*/
/*}*/

.console {
    width: 100%;
    color: black !important;
}

.upper-tab-panel {
    height: 40px;
    width: calc(100%);
    color: #1d1e1f;
    padding: 10px;
    //margin: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--black);
    border-top: 1px solid var(--white);
    //border-radius: 8px;
}

.upper-tab-panel .font-selector,
.upper-tab-panel .lang-selector,
.upper-tab-panel .theme-selector,
.upper-tab-panel .editor-control {
    margin-left: 20px;
}

.upper-tab-panel .font-selector .font-size-value,
.upper-tab-panel .lang-selector .lang-value,
.upper-tab-panel .theme-selector .theme-value,
.upper-tab-panel .editor-control .fullscreen-btn {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding: 3px 5px;
    cursor: pointer;
    color: #ffffff;
    font-size: var(--para);
}

.upper-tab-panel .editor-control .fullscreen-btn span {
    color: #ffffff;
}

/*.upper-tab-panel .font-selector .font-size-value:hover,*/
/*.upper-tab-panel .lang-selector .lang-value:hover,*/
/*.upper-tab-panel .editor-control .fullscreen-btn:hover,*/
/*.upper-tab-panel .editor-control .theme-value:hover {*/
/*	background-color: #eeeeee;*/
/*}*/

.upper-tab-panel .font-selector .font-list,
.upper-tab-panel .theme-selector .theme-list,
.upper-tab-panel .lang-selector .lang-list {
    min-width: 100px;
    background-color: white;
    z-index: 100;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}

.upper-tab-panel .font-selector .font-list li,
.upper-tab-panel .theme-selector .theme-list li,
.upper-tab-panel .lang-selector .lang-list li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    padding: 5px 10px;
}

.upper-tab-panel .font-selector .font-list li:hover,
.upper-tab-panel .theme-selector .theme-list li:hover,
.upper-tab-panel .lang-selector .lang-list li:hover {
    background-color: #eeeeee;
}

.reflex-container > .reflex-splitter {
    border: none !important;
    background-color: #ffffff;
}

.reflex-container > .reflex-splitter-console {
    background-color: var(--black) !important;
}

.upper-tab-panel .editor-control .fullscreen-btn {
    color: #1d1e1f;
}

.upper-tabpanel__settings {
    column-gap: 21px;
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.upper-tabpanel__settings svg {
    cursor: pointer;
}

.text-editor-open {
    background: #012555;
    height: 60%;
}

.text-editor-close {
    height: 100% !important;
}

.idecustom-btns {
    border: 1px solid;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;
    background: #0d0f17;
    width: 100px;
}

.bottom-tab-panel {
    width: 100%;
    height: 50px;
    bottom: 0px;
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.console-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: 5px 10px;
    color: var(--black);
    background-color: #eeeeee;
}

.test-code-btns {
    display: flex;
    align-items: center;
    justify-content: center;
}

.run-code-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: 5px 10px;
    color: var(--black);
    background-color: #eeeeee;
    margin-right: 20px;
}

.submit-code-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: 5px 10px;
    color: var(--black);
    background-color: #eeeeee;
}

.testcase-dialog {
    overflow: auto;
    padding: 20px;
    border-top: 2px solid lightgray;
    background: #012555;
    color: #ffffff;
    border-bottom: 2px solid lightgray;
    border-left: 2px solid lightgray;
}

.testcase-dialog__info {
    display: flex;
    column-gap: 50px;
    font-size: 13px;
}

.testcase-dialog h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: darkslategray;
    cursor: pointer;
}

.custom-testcase-dialog {
    width: 100%;
    padding: 20px;
    background: #ffffff;
    border-top: 2px solid lightgray;
    color: black;
}

.custom-tabs {
}

.custom-tc-input {
    width: 100%;
    margin: 10px 0;
    resize: none;
    font-family: monospace;
    height: 200px;
    border-radius: 3px;
    overflow: auto;
    outline: none;
    padding: 10px;
    background-color: #ffffff;
    position: relative;
    color: #1d1e1f;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.question-panel {
    padding: 10px 5px;
}

.markdown-body {
    font-size: 14px;
}

.cm-content .cm-line span {
    font-family: 'Source Code Pro', monospace !important;
}
