.all-questions-page {
    width: 100%;
    padding: 20px 10%;
    height: calc(100vh - 70px);
    padding-bottom: 50px;
    overflow: scroll;

    .heading-container {
        display: flex;
        align-items: center;
        background-color: #e3ffed;
        margin-bottom: 10px;
        justify-content: space-between;
        padding-right: 20px;
        border-radius: 5px;

        .heading {
            font-size: var(--small-heading);
            letter-spacing: 1px;
            background-color: var(--green);
            clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
            height: 50px;
            width: 55%;

            padding: 10px 50px 10px 15px;
            color: var(--white);
            display: flex;
            align-items: center;
            justify-content: start;
            border-radius: 5px 0px 0px 5px;
        }
    }

    .all-questions-list {
        li {
            background: #ffffff;
            /* COMPONENT / Stroke Colour */

            //border: 1px solid #E0E0E0;
            border-radius: 8px;
            margin: 20px 0;
            padding: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
                rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

            .question-details {
                h1 {
                    margin-bottom: 10px;
                    //text-transform: capitalize;
                }
            }

            button {
                padding: 10px;
                width: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                border: none;
                cursor: pointer;

                p {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    letter-spacing: 1px;
                }
            }

            .solved-btn {
                background-color: var(--light-green);

                span {
                    color: var(--green);
                }
            }

            .unsolved-btn {
                background-color: var(--dark-blue);

                p {
                    color: var(--white);
                    font-size: var(--para);

                    svg {
                        stroke: #fff;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .all-questions-page {
        padding: 15px;

        .heading-container {
            .heading {
                font-size: 14px;
            }

            h3 {
                font-size: 14px;
            }
        }
        .questions {
            .question-details {
                width: 50%;
                h1 {
                    font-size: 14px;
                }

                p {
                    font-size: 10px;
                }
            }

            .solved-btn,
            .unsolved-btn {
                font-size: 10px;
                padding: 8px;
                font-weight: 500;
            }

            .solved-btn {
                .attempted-question {
                    font-size: 10px;
                    font-weight: 500;
                }
            }
        }
    }
}
