.navigation {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    nav {
        width: 80%;
        margin: auto;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        .logo {
            width: 150px;

            img {
                width: 100%;
            }
        }

        .navigation-options {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;

            .user-info {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                h3 {
                    padding: 5px 10px;
                    margin: auto 20px;
                    font-size: 20px;
                    text-transform: capitalize;
                    letter-spacing: 1px;
                }

                button {
                    padding: 10px 20px;
                    background-color: var(--red);
                    border: none;
                    border-radius: 4px;
                    color: var(--white);
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    cursor: pointer;
                }
            }
        }
    }
}
