$primary: #2f80ed;
$primary-d1: #2666be;
$disable: #aeb3bb;
$solved: rgba(227, 255, 237);
$solved-d1: #12b76a;

.elm-btn {
	background: $primary-d1;
	border-radius: 6px;
	padding: 8px 20px 8px 24px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	border: none !important;

	&.btn-disable {
		color: #696a6b;
		background: #e5e6e7;
	}

	&.primary {
		color: #fff;
		background: $primary;
	}

	&.btn-outline {
		border: none !important;
		color: $primary;
		background: #fff;
	}

	&.btn-solved {
		background-color: $solved !important;
		color: $solved-d1 !important;
		border: 1px solid $solved-d1 !important;
	}

	.btn-icon {
		margin: 0px 5px;
	}
}
