.all-feedback-modal-rating-wrapper {
	border-top: 6px solid #2e90fa;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	width: inherit;
	min-width: 410px;
	transition: height 0.8s ease-in-out;

	.modal-title {
		padding: 16px;
		color: #000;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px; /* 150% */
		letter-spacing: -0.32px;
		// border-top: 4px solid var(--primary-primary, #2f80ed);
		background: var(--White, #fff);
		box-shadow: 0px 8px 16px -6px rgba(24, 39, 75, 0.08),
			0px 6px 8px -6px rgba(24, 39, 75, 0.12);
	}

	.modal-rating-container {
		padding: 16px 0 0 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		gap: 30px;
		.rating-wrapper {
			padding: 0 24px 0 24px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 10px;

			.rating-holder {
				display: flex;
				padding: 16px 0px;
				justify-content: space-between;
				align-items: flex-start;
				align-self: stretch;
				.rating-component-wrapper {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 5px;

					.rating-component {
						height: 20px;
						width: 20px;
						border-radius: 50%;
						border: 1px solid #e0e0e0;
						cursor: pointer;

						&:hover {
							border: 5px solid #2666be;
						}

						&.selected {
							border: 5px solid #2666be;
						}
					}

					.rating-number {
						color: #000;
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: 22px; /* 157.143% */
						letter-spacing: -0.28px;
					}
				}
			}

			.modal-rating-text {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 10px;

				.text {
					color: #000;
					font-size: 14px;
					font-weight: 600;
					line-height: 22px; /* 157.143% */
					letter-spacing: -0.28px;
				}
			}
		}

		.modal-comment-wrapper {
			padding: 0 24px 0 24px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			gap: 30px;

			.checkbox-wrapper {
				width: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 10px;

				.checkbox-item {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					gap: 8px;

					.text {
						color: #000;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 22px; /* 157.143% */
						letter-spacing: -0.28px;
					}
				}
			}

			.modal-input-feedback-wrapper {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 10px;

				.modal-input-feedback-title {
					color: #000;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 22px; /* 157.143% */
					letter-spacing: -0.28px;
				}

				.modal-input-feedback {
					width: 100%;
					padding: 8px 12px;
					border-radius: 4px;
					border: 1px solid var(--stroke-100, #eaecf0);
					background: #fff;
					font-size: 12px;
					font-weight: 500;
					line-height: 22px; /* 157.143% */
					letter-spacing: -0.28px;
					color: #000;
					resize: none;
				}
			}
		}

		.modal-footer-wrapper {
			padding: 16px 24px 16px 24px;
			border-top: 1px solid var(--stroke-100, #eaecf0);
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.disabled-btn {
				background: #b3b0b0;
				border: 1px solid #f0f0f0;
				color: #000;
			}
		}
	}
}