.web-idle {
  height: calc(100vh - 80px);
  width: 100%;
}

.web-idle .left-pane {
  height: 100%;
}

.web-idle .right-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.web-idle .git-panel {
  text-align: center;
}

.web-idle .git-panel h2 {
  margin-bottom: 20px;
}

.web-idle .git-panel a {
  background-color: #ebf3fe;
  padding: 5px 10px;
  color: #2f80ed;
}

.web-idle .git-panel svg {
  color: #1d1e1f;
  font-size: 30px;
}

.web-idle .git-panel .startbtn {
  background-color: #ebf3fe !important;
  padding: 5px 10px !important;
  color: #2f80ed !important;
}
