@import url('navigation/dash-navigation.scss');
@import url('screens/dashboard.scss');
@import url('screens/buffer.scss');
@import url('navigation/contest-navigation.scss');
@import url('screens/questions.scss');
@import url('screens/codeidle.scss');
@import url('screens/contest.scss');
@import url('screens/web-idle.scss');
@import url('screens/questionscreen.scss');
@import url('screens/errorfallback.scss');
@import url('screens/redirectModal.scss');
@import url('elements/button.scss');
@import url('components/feedbackModal.scss');

.CodeEditor {
    width: 100%;
    height: 100%;
}
